// i18next-extract-mark-ns-start multibanco

//Payment methods
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql, PageProps} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Images
import multibanco_monei from 'images/multibanco_monei.svg';
import cards from 'images/cards.svg';
import heartbubble from 'images/heartbubble.svg';
import shield from 'images/shield.svg';
import password from 'images/password.svg';
import index1 from 'images/payment_gateway.svg';

// Clients

import React from 'react';
import styled from 'styled-components';
import {AnchorLink} from 'components/AnchorLink';
import {PaymentMethodsLinkList} from 'components/landings/PaymetMethodsLinkList';
import {BlogLink} from 'components/links/Blog';
import {IntegrationGuide} from 'components/payment-methods/IntegrationGuide';
import {InternalPageLink} from 'components/links/Pages';
import CardsContainer from 'components/CardsContainer';
import {Card} from 'components/Card';
import {ScreenSizes} from '../../../types/responsive';
import {FaqsSection} from 'components/FaqsSection';
import {GoogleReviews, Place} from 'components/GoogleReviews';

const Bold = styled.span`
  display: inline;
  font-weight: 600;
`;

const CardWrapper = styled.div`
  max-width: 230px;
`;

const CardImage = styled.img`
  position: absolute;
`;

const IndexImage = styled.img`
  position: absolute;
  left: 56%;
  top: 20px;
  width: 540px;
  z-index: 9;

  @media (max-width: 1024px) {
    left: 60%;
    width: 45%;
    top: 1%;
  }
`;

const IndexBackground = styled.div`
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: -30%;
    height: 1256px;
    width: 1203px;
    background: linear-gradient(353.74deg, #41efd6 -47.57%, #aab4e9 13.85%);
    transform: matrix(2, 0, 0, -1, 1248, 4) rotate(47deg) skew(7deg, 1.6deg);
    @media (max-width: 1024px) {
      top: -32%;
      transform: matrix(2, 0, 0, -1, 1185, 25) rotate(44.5deg) skew(7deg, 1.6deg);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    transform: rotate(40deg) matrix(1, 0, 0, -1, 459, -315);
    left: 47%;
    top: -20%;
    opacity: 1;
    height: 972px;
    width: 1289px;
    background: linear-gradient(353.74deg, #41efd6 -41.57%, #aab4e9 53.85%);
    @media (max-width: 1024px) {
      top: -35%;
      transform: rotate(34deg) matrix(1, 0, 0, -1, 470, -240);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }
`;

const FirstSection = styled(Section)`
  position: relative;
  padding-top: 0;

  @media (max-width: 768px) {
    margin-top: 40px;
  }
`;

const FirstSectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    margin-bottom: 24px;
  }
`;

const IndexContent = styled.div`
  padding-top: 100px;
  padding-bottom: 0;
  max-width: 550px;
  min-height: 600px;
  @media (max-width: ${ScreenSizes.md}) {
    max-width: 500px;
    min-height: 510px;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    max-width: 100%;
    min-height: 410px;
  }
`;

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const OrderList = styled.ol``;

const Multibanco: React.FC<PageProps> = (props) => {
  const {t} = useI18next();
  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };
  const faqs = [
    {
      header: t('Which payment methods does MONEI support?'),
      text: t(
        'Consult our payment methods page to see all the local payment methods you can accept when you integrate with MONEI.'
      ),
      content: (
        <Trans>
          Consult our <InternalPageLink slug="features">payment methods page </InternalPageLink>
          to see all the local payment methods you can accept when you integrate with MONEI.
        </Trans>
      )
    },
    {
      header: t('What are all the different types of local payment methods?'),
      text: t(
        'There are many local payment methods used across the world. You don’t have to accept them all. Instead, focus on the ones most relevant to your target market.'
      ),
      content: (
        <Trans>
          There are many <BlogLink slug="local-payment-methods">local payment methods</BlogLink>{' '}
          used across the world. You don’t have to accept them all. Instead, focus on the ones most
          relevant to your target market.
        </Trans>
      )
    },
    {
      header: t('What are interchange fees?'),
      text: t(
        'They are the fees you must pay to card-issuing banks when customers use a credit or debit card to complete a purchase from your business. Learn how they work.'
      ),
      content: (
        <Trans>
          They are the fees you must pay to card-issuing banks when customers use a credit or debit
          card to complete a purchase from your business.{' '}
          <InternalPageLink slug="interchange-fee-calculator">
            Learn how they work.
          </InternalPageLink>
        </Trans>
      )
    },
    {
      header: t('Are there setup fees for MONEI? '),
      text: t(
        'No. And transaction fees are dynamic. As your sales increase, your fees per transaction will decrease. Learn more about MONEI’s pricing.'
      ),
      content: (
        <Trans>
          No. And transaction fees are dynamic. As your sales increase, your fees per transaction
          will decrease. Learn more about{' '}
          <InternalPageLink slug="pricing">MONEI’s pricing. </InternalPageLink>
        </Trans>
      )
    }
  ];

  return (
    <>
      <SEO
        path="multibanco"
        title={t('Accept Multibanco Payments')}
        description={t(
          'Accepting Multibanco payments will help you reach Portuguese customers and increase sales. Add Portugals #1 local payment method to your online store ››'
        )}
      />

      <IndexBackground>
        <Content>
          <IndexContent>
            <SectionHeader underline tagName="h1">
              <Trans>Multibanco</Trans>
            </SectionHeader>
            <Trans parent="p">
              Founded in 1985 and currently used by 90% of consumers in the country, Multibanco is
              Portugal’s most popular local payment method.
            </Trans>
            <Trans parent="p">
              Customers use it to make post-purchase payments for online orders using cash at 13,000
              ATMs across Portugal. Or they can pay immediately through the Multibanco online
              banking environment.{' '}
            </Trans>
            <SectionActions>
              <SignUpButton variant="light" style={{marginRight: 15}}>
                <Trans>Open an Account</Trans>
              </SignUpButton>
              <ContactSalesButton />
            </SectionActions>
          </IndexContent>
        </Content>
        <IndexImage
          src={multibanco_monei}
          alt="Multibanco monei"
          title="MONEI and Multibanco"
          className="hide-on-mobile"
        />
      </IndexBackground>

      <Content style={{marginTop: '40px'}}>
        <Section centered textAlign="center" column>
          <SectionHeader>
            <Trans>Benefits of accepting Multibanco</Trans>
          </SectionHeader>
          <Trans parent="p">
            Accept Multibanco in your e-commerce store to let shoppers pay via their online banking
            app without sharing cards or personal information. It’s fast, secure, and integrates
            with the main banks.
          </Trans>
        </Section>
        <CardsContainer>
          <Card>
            <CardImage src={cards} alt="" style={{top: -25}} />
            <Trans parent="h4">Avoid chargebacks</Trans>
            <CardWrapper>
              <Trans parent="p">
                Transactions are paid in cash, eliminating the risk of chargebacks so you always get
                paid
              </Trans>
            </CardWrapper>
          </Card>
          <Card>
            <CardImage src={shield} alt="" style={{top: -35, width: 70}} />
            <Trans parent="h4">Guarantee security</Trans>
            <CardWrapper>
              <Trans parent="p">
                No banking or personal information is shared while customers pay at an ATM or via
                their banking app
              </Trans>
            </CardWrapper>
          </Card>
          <Card>
            <CardImage src={heartbubble} alt="" style={{top: -60, right: 110, width: 110}} />
            <Trans parent="h4">Build trust</Trans>
            <CardWrapper>
              <Trans parent="p">
                Multibanco’s popularity and proven track record will boost trust in your business
                and improve conversion rates
              </Trans>
            </CardWrapper>
          </Card>
        </CardsContainer>
        <Section
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '0px',
            paddingBottom: '140px'
          }}>
          <SectionActions>
            <SignUpButton variant="dark">
              <Trans>Discover MONEI</Trans>
            </SignUpButton>
          </SectionActions>
        </Section>
      </Content>

      <Background>
        <Content>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>What is the Multibanco payment process?</Trans>
              </SectionHeader>
              <Trans parent="p">
                Multibanco lets e-commerce shoppers pay immediately through their online banking
                environment or at an ATM.
              </Trans>
              <OrderList>
                <Trans parent="li">
                  <Bold>Choose Multibanco at checkout.</Bold> The customer selects Multibanco on the
                  payment page.
                </Trans>
                <Trans parent="li">
                  <Bold>Payment authorization.</Bold> The customer pays immediately via their online
                  banking app or chooses to receive a code to pay at any Multibanco cash machine
                  within seven days.
                </Trans>
                <Trans parent="li">
                  <Bold>Transaction completed.</Bold> The customer completes the payment process and
                  you’re both notified that the payment has been received.
                </Trans>
              </OrderList>
              <Trans>
                With Multibanco payments, you’ll reach more people and improve sales. But
                diversifying your payment options shouldn’t end there. Staying competitive requires
                accepting{' '}
                <InternalPageLink slug="payment-methods">many payment methods</InternalPageLink>{' '}
                including{' '}
                <InternalPageLink slug="payment-methods/credit-cards">cards</InternalPageLink> and{' '}
                <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>{' '}
                like{' '}
              </Trans>
              <PaymentMethodsLinkList exclude={['multibanco', 'credit-cards']} endTag={t('and')} />.
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>

          <Section>
            <SectionImage
              src={password}
              alt="Multibanco safe payment method"
              title="Multibanco safe paymont method"
              width={247}
              height={217}
              mobileWidth={200}
            />
            <div>
              <SectionHeader>
                <Trans> Is Multibanco a safe payment method?</Trans>
              </SectionHeader>

              <Trans parent="p">
                Multibanco shines as Portugal's most popular and secure payment method. Customers
                can complete transactions without disclosing their actual card details to merchants.
              </Trans>
              <Trans parent="p">
                To authorize transactions, customers must enter their six-digit PIN, a {''}
                <BlogLink slug="sca">Strong Customer Authentication (SCA)</BlogLink> feature that
                adds an extra layer of security to every payment.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Securely accept Multibanco</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <IntegrationGuide title={<Trans>Integrate Multibanco with your online store</Trans>}>
        <Trans>
          <AnchorLink href="https://support.monei.com/hc/es-es/requests/new">Contact us</AnchorLink>{' '}
          to add Multibanco to your e-commerce platform or custom-built website and start accepting
          cross-border payments today.
        </Trans>
      </IntegrationGuide>
      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>Multibanco FAQ</Trans>} />
        </Section>
      </Content>
    </>
  );
};

export default Multibanco;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "multibanco"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
